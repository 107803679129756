import React from "react";
import Layout from "../components/layout";
import CustomNavbar from "../components/Custom/Navbar";
import SmallHeader from "../components/Custom/Headers/SmallHeader";
import { Container } from "reactstrap";
import withUIKit from "../withUIKit";

const NotFoundPage = props => (
  <Layout {...props} >
    <CustomNavbar {...props} />
    <SmallHeader />
    <hr />
    <div className="section landing-section">
      <Container>
        <h2 className="text-center">404</h2>
        <p>Die Seite wurde nicht gefunden</p>
      </Container>
    </div>
  </Layout>
);

export default withUIKit(NotFoundPage);
